<template>
  <div>
    <div class="title">企业官媒平台认证费用</div>
    <div
      style="
        font-size: 18px;
        font-weight: 400;
        color: #1a1a1a;
        margin-top: 20px;
      "
    >
      {{ money }}元
    </div>
    <div
      style="
        display: flex;
        width: 580px;
        justify-content: space-between;
        margin-top: 50px;
        margin-left: -30px;
      "
    >
      <img :src="wxcode" class="window" />
      <img :src="zfbcode" class="window" />
    </div>
    <div
      style="
        display: flex;
        width: 580px;
        margin-top: 20px;
        font-size: 14px;
        margin-right: 30px;
        margin-left: -30px;
      "
    >
      <div style="margin-left: 20px">请用微信扫描二维码完成支付</div>
      <div style="margin-left: 150px">请用支付宝扫描二维码完成支付</div>
    </div>
    <div style="margin-top: 100px; color: #cccccc">
      企业官媒认证服务费基于企业官媒平台认证费用，用户申请时均应按照<a
        href="https://view.officeapps.live.com/op/view.aspx?src=https://video-1300439097.cos.ap-chongqing.myqcloud.com/school/企业官媒平台认证服务协议.docx"
        >《企业官媒平台认证服务协议》</a
      >中的费率标准支付平台认证服务费。
    </div>
    <a-modal
      title="支付结果"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :closable="false"
      okText="确定"
      cancelText="返回首页"
    >
      <img src="../assets/renzhengchenggong.png" style="margin-left: 70px" />
      <a-result status="支付成功" title="恭喜您平台认证成功！" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["phone","num"],
  data() {
    return {
      wxcode: "",
      zfbcode: "",
      wxcodeid: "",
      zfbcodeid: "",
      visible: false,
      money: "",
    }
  },
  created() {
    this.generate()
    this.getmoney()
	this.money = this.num
    window.timer = setInterval(() => {
      setTimeout(this.wxscan(), 0)
    }, 3000)
  },
  methods: {
    async generate() {
      const phone = this.phone
	  console.log(this.phone+","+this.num)
      const res = await this.request({
        method: "post",
        url: "https://api.iptzx.com/crmapi/applyPay",
        data: {
          phone,
        },
      })
      this.wxcode = res.data.data.wx_url
      this.zfbcode = res.data.data.ali_url
      this.wxcodeid = res.data.data.wx_order
      this.zfbcodeid = res.data.data.ali_order
    },
    async wxscan() {
      const res = await this.request({
        method: "post",
        url: "https://api.iptzx.com/crmapi/checkOrder",
        data: {
          order_id: [this.wxcodeid, this.zfbcodeid],
        },
      })
      if (res.data.code === 1) {
        this.visible = true
        clearInterval(window.timer)
      }
    },
    async getmoney() {
      const res = await this.request({
        method: "post",
        url: "https://api.iptzx.com/Crmapi/rzMoney",
        data: {},
      })
      // this.money = res.data.data
    },
    handleCancel() {
      this.visible = false
      this.$router.push("/")
    },
    handleOk() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-top: 90px;
}
.window {
  width: 233px;
  height: 233px;
  background: #ffffff;
}
</style>
