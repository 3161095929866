<template>
  <div>
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 15 }"
      @submit="handleSubmit"
      style="margin-top: 80px"
      labelAlign="left"
    >
      <a-form-item label="平台注册类型" has-feedback>
        <a-select
          v-decorator="[
            'select',
            {
              rules: [{ required: true, message: '请选择注册类型!' }],
            },
          ]"
          placeholder="请输入平台类型"
        >
          <a-select-option value="enterprise"> 企业 </a-select-option>
          <a-select-option value="personal"> 个人 </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-bind="formItemLayout" label="平台名称">
        <a-row :gutter="8">
          <a-col :span="20">
            <a-input
              v-decorator="[
                'captcha',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入您的平台名称',
                    },
                  ],
                },
              ]"
            />
          </a-col>
          <a-col :span="2">
            <a-button @click="enterCaptcha">核验</a-button>
          </a-col>
        </a-row>
        <div style="font-size: 14px; color: #737373; width: 491px">
          请填写未在企业官媒平台认证的名称，一个手机号仅注册一个企业官媒平台名称
        </div>
        <div style="width: 100px; color: red; font-size: 20px">
          {{ cxRes }}
        </div>
      </a-form-item>
      <a-form-item label="行业" has-feedback>
        <a-select
          v-decorator="[
            'trade',
            {
              rules: [{ required: true, message: '请选择您的行业' }],
            },
          ]"
          placeholder="请选择行业"
        >
          <a-select-option
            :value="item.name"
            v-for="(item, index) in industry"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
	  <a-form-item v-bind="formItemLayout" label="开通年限">
	    <a-input
	      v-decorator="[
	        'year',
	        {
	          rules: [
	            {
	              required: true,
	              message: '请输入年限',
	            },
	          ],
	        },
	      ]"
	    />
		</a-form-item>
      <!-- <a-form-item v-bind="formItemLayout" label="所在地址">
        <a-cascader
          v-decorator="[
            'address',
            { rules: [{ required: true, message: '请输入您的地址!' }] },
          ]"
          :options="city"
          change-on-select
          placeholder="请选择地址"
          :field-names="{
            label: 'label',
            value: 'label',
            children: 'children',
          }"
        />
      </a-form-item> -->
      <!-- <a-form-item label="详细地址">
        <a-input
          v-decorator="[
            'detailedAddress',
            { rules: [{ required: true, message: '请输入您的详细地址!' }] },
          ]"
          placeholder="请输入详细地址"
        />
      </a-form-item> -->
      <div v-if="form.getFieldValue('select') === 'enterprise'">
        <div style="width: 57px; height: 15px; font-size: 14px; color: #1a1a1a">
          企业信息
        </div>
        <div
          style="
            width: 625px;
            height: 1px;
            background: #e4e8eb;
            margin-top: 10px;
          "
        ></div>
        <a-form-item label="公司名称" style="margin-top: 40px">
          <a-input
            v-decorator="[
              'corporateName',
              { rules: [{ required: true, message: '请输入您的公司名称' }] },
            ]"
            placeholder="请输入公司名称"
          />
        </a-form-item>
        <a-form-item label="注册人名称">
          <a-input
            v-decorator="[
              'registrant',
              { rules: [{ required: true, message: '请输入注册人名称' }] },
            ]"
            placeholder="请输入注册人名称"
          />
        </a-form-item>
        <!-- <a-form-item label="身份证号">
          <a-input
            v-decorator="[
              'id',
              {
                rules: [
                  { required: true, message: '请输入身份证号码!', min: 18 },
                ],
              },
            ]"
            placeholder="请输入身份证号"
          />
        </a-form-item> -->
        <!-- <a-form-item label="工商营业执照:">
          <div style="display: flex; wdith: 580px">
            <div>
              <div style="font-size: 14px">
                请上传<span style="color: #0c4aff">最新的营业执照</span>
              </div>
              <div style="font-size: 12px; color: #cccccc; width: 550px">
                格式要求：原件照片、扫描件或者加盖公章的复印件，支持jpg、jpeg、png格式照片，大小不超过5M。
              </div>
              <a-upload
                v-decorator="[
                  'license',
                  {
                    rules: [
                      { required: true, message: '请上传最新的营业执照!' },
                    ],
                  },
                ]"
                action="https://api.iptzx.com/Other?act=upFile"
                list-type="picture"
                v-bind:data="{
                  type: 'project',
                }"
              >
                <a-button style="margin-top: 20px">
                  <a-icon type="upload" /> 上传
                </a-button>
              </a-upload>
            </div>
          </div>
        </a-form-item> -->
        <!-- <a-form-item label="身份证正面:">
          <div style="display: flex">
            <div>
              <div style="font-size: 14px">
                请上传<span style="color: #0c4aff">身份证正面</span>
              </div>
              <div
                style="
                  font-size: 12px;
                  font-weight: 400;
                  color: #cccccc;
                  width: 550px;
                "
              >
                格式要求：原件照片、扫描件，支持jpg、jpeg、png格式照片，大小不超过5M。
              </div>
              <a-upload
                v-decorator="[
                  'frontid',
                  {
                    rules: [{ required: true, message: '请上传身份证正面!' }],
                  },
                ]"
                action="https://api.iptzx.com/Other?act=upFile"
                list-type="picture"
                v-bind:data="{
                  type: 'project',
                }"
              >
                <a-button style="margin-top: 20px">
                  <a-icon type="upload" /> 上传
                </a-button>
              </a-upload>
            </div>
          </div>
        </a-form-item> -->
        <!-- <a-form-item label="身份证反面:">
          <div style="display: flex">
            <div>
              <div style="font-size: 14px">
                请上传<span style="color: #0c4aff">身份证反面</span>
              </div>
              <div style="font-size: 12px; color: #cccccc; width: 550px">
                格式要求：原件照片、扫描件，支持jpg、jpeg、png格式照片，大小不超过5M。
              </div>
              <a-upload
                v-decorator="[
                  'reverseid',
                  {
                    rules: [{ required: true, message: '请上传身份证反面!' }],
                  },
                ]"
                action="https://api.iptzx.com/Other?act=upFile"
                list-type="picture"
                v-bind:data="{
                  type: 'project',
                }"
              >
                <a-button style="margin-top: 20px; margin-bottom: 60px">
                  <a-icon type="upload" /> 上传
                </a-button>
              </a-upload>
            </div>
          </div>
        </a-form-item> -->
      </div>
      <div v-if="form.getFieldValue('select') === 'personal'">
        <div style="width: 57px; height: 15px; font-size: 14px; color: #1a1a1a">
          个人信息
        </div>
        <div
          style="
            width: 625px;
            height: 1px;
            background: #e4e8eb;
            margin-top: 10px;
          "
        ></div>
        <a-form-item label="注册人名称" style="margin-top: 40px">
          <a-input
            v-decorator="[
              'registrant',
              { rules: [{ required: true, message: '请输入注册人名称' }] },
            ]"
            placeholder="请输入注册人名称"
          />
        </a-form-item>
        <!-- <a-form-item label="身份证号">
          <a-input
            v-decorator="[
              'id',
              {
                rules: [
                  { required: true, message: '请输入身份证号码!', min: 18 },
                ],
              },
            ]"
            placeholder="请输入身份证号"
          />
        </a-form-item> -->
        <!-- <a-form-item label="身份证正面:">
          <div style="display: flex">
            <div>
              <div style="font-size: 14px">
                请上传<span style="color: #0c4aff">身份证正面</span>
              </div>
              <div style="font-size: 12px; width: 550px; color: #cccccc">
                格式要求：原件照片、扫描件，支持jpg、jpeg、png格式照片，大小不超过5M。
              </div>
              <a-upload
                v-decorator="[
                  'frontid',
                  {
                    rules: [{ required: true, message: '请上传身份证正面!' }],
                  },
                ]"
                action="https://api.iptzx.com/Other?act=upFile"
                list-type="picture"
                v-bind:data="{
                  type: 'project',
                }"
              >
                <a-button style="margin-top: 20px">
                  <a-icon type="upload" /> 上传
                </a-button>
              </a-upload>
            </div>
          </div>
        </a-form-item> -->
        <!-- <a-form-item label="身份证反面:">
          <div style="display: flex; margin-top: 20px">
            <div>
              <div style="font-size: 14px">
                请上传<span style="color: #0c4aff">身份证反面</span>
              </div>
              <div style="font-size: 12px; width: 550px; color: #cccccc">
                格式要求：原件照片、扫描件，支持jpg、jpeg、png格式照片，大小不超过5M。
              </div>
              <a-upload
                v-decorator="[
                  'reverseid',
                  {
                    rules: [{ required: true, message: '请上传身份证反面!' }],
                  },
                ]"
                action="https://api.iptzx.com/Other?act=upFile"
                list-type="picture"
                v-bind:data="{
                  type: 'project',
                }"
              >
                <a-button style="margin-top: 20px">
                  <a-icon type="upload" /> 上传
                </a-button>
              </a-upload>
            </div>
          </div>
        </a-form-item> -->
      </div>
      <!-- <a-button type="primary" html-type="submit" style="margin-bottom: 40px">
        提交信息
      </a-button> -->
      <!-- <a-button
        :style="{ marginLeft: '8px', marginBottom: '40px' }"
        @click="enterback"
      >
        下一步
      </a-button> -->
	  <a-button type="primary" html-type="submit" class="button" :style="{ marginLeft: '8px', marginBottom: '40px' }">
	    提交信息
	  </a-button>
    </a-form>
  </div>
</template>

<script>
import industry from "../common/industry"
import city from "../common/city"
import asKey from "../common/asKey"
export default {
  props: ["phone", "password"],
  data() {
    return {
      industry,
      city,
      select: "",
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      captcha: "",
      cxRes: "",
	  year: "",
	  num:"",
      FormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      msg: "",
    }
  },
  methods: {
    async handleSubmit(e) {
      const form = this.form
      const plat_name = form.getFieldValue("captcha")
      const industry = form.getFieldValue("trade")
      const year = form.getFieldValue("year")
      // const address = form.getFieldValue("detailedAddress")
      const reg_username = form.getFieldValue("registrant")
      // const reg_idcard = form.getFieldValue("id")
      // const idcard_front = form.getFieldValue("frontid").file.response.data
      // const idcard_back = form.getFieldValue("reverseid").file.response.data
      const company_name = form.getFieldValue("corporateName")
      // const dizhi = form.getFieldValue("address")
      const type = form.getFieldValue("select")
      const phone = this.phone
      const password = this.password
      // const city = dizhi[1]
      // const province = dizhi[0]
      e.preventDefault()
      if (type === "enterprise") {
        // const business_license =
        //   form.getFieldValue("license").file.response.data
        const res = await this.request({
          method: "post",
          url: "https://api.iptzx.com/crmapi/applyInfo",
          data: {
            register_type: 2,
            phone,
            // password,
            plat_name,
            industry,
            reg_username,
            company_name,
			year
          },
        })
        this.msg = res.data.data
		this.num = res.data
		// console.log(res.data)
        if (res.data.code === 200) {
          this.form.validateFields((err, values) => {
            if (!err) {
              console.log("Received values of form: ", values)
              // this.$router.push("/Home")
              // setTimeout(() => this.$router.push({ path: "/Home" }), 3000)
              this.$emit("form3")
			  this.$emit("number",res.data)
              this.$notification.open({
                message: "提交完成！",
                // description: "提交完成！正在返回首页，请稍等！",
    			description: "提交完成！请选择支付方式进行付款，请稍等！",
                onClick: () => {
                  console.log("Notification Clicked!")
                },
              })
            }
          })
        } else {
          this.$message.info(this.msg)
        }
      } else {
        const res = await this.request({
          method: "post",
          url: "https://api.iptzx.com/crmapi/applyInfo",
          data: {
            register_type: 1,
            phone,
            password,
            plat_name,
            industry,
            reg_username,
			year
          },
        })
        this.msg = res.data.data
		this.num = res.data.data
		console.log(res.data)
        if (res.data.code === 200) {
          this.form.validateFields((err, values) => {
            if (!err) {
              console.log("Received values of form: ", values)
              // setTimeout(() => this.$router.push({ path: "/Home" }), 3000)
              this.$emit("form3")
			  this.$emit("number",res.data)
              this.$notification.open({
                message: "提交完成！",
                // description: "提交完成！正在返回首页，请稍等！",
                description: "提交完成！请选择支付方式进行付款，请稍等！",
                onClick: () => {
                  console.log("Notification Clicked!")
                },
              })
            }
          })
        } else {
          this.$message.info(this.msg)
        }
      }
    },
    enterback() {
      // this.$emit("form2")
      this.$emit("form3")
    },
    async enterCaptcha() {
      const form = this.form
      const name = form.getFieldValue("captcha")
      if (form.getFieldValue("captcha") === undefined) {
        return alert("请输入查询平台名称")
      }
      const res = await this.request({
        method: "post",
        url: "https://pc.iptzx.com/User?act=checkPt",
        data: {
          data: asKey
            .encrypt({
              name,
              timeStamp: Date.parse(new Date()),
            })
            .toString(),
        },
      })
      this.cxRes = res.data
    },
  },
}
</script>
<style lang="less" scoped>
.license {
  width: 125px;
  height: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #1a1a1a;
  margin-top: 20px;
}
</style>
